import { InfiniteLoader } from 'components/atoms'
import { ProdottoCard } from 'components/molecules'
import { Filters } from 'components/organisms'
import { useEffect, useMemo, useRef, useState } from 'react'
import { api, inViewport, LABEL_ORDINAMENTO } from 'utils'
import { LISTING } from 'utils/endpoint'
import { trackingProductImpression } from 'utils/gtm'
import { useIsClient, useTrans } from 'utils/hooks'

function Listing({ initial, classes, categoria }) {
  const [prodotti, setProdotti] = useState(initial.elementi)
  const [prodottiInfinite, setProdottiInfinite] = useState([])
  const [numPages, setNumPages] = useState(initial.num_pages)
  const [page, setPage] = useState(initial.page)

  const isClientSide = useIsClient(false)

  const t = useTrans()

  const attributiToFiltri = (attrs) => {
    return attrs.map((attr) => ({
      name: attr.id,
      label: attr.nome,
      valori_disponibili: attr.valori_disponibili.map((v) => ({
        value: v.id,
        label: v.nome,
        selezionato: v.selezionato,
      })),
    }))
  }

  const ordinamentiToFiltri = (ords, selected) => {
    return ords?.map((o) => ({
      label: o.placeholder,
      value: o.value,
      selected: o.value === selected,
    }))
  }

  const [disabilitati, setDisabilitati] = useState([])

  const [filtri, setFiltri] = useState(attributiToFiltri(initial.attributi))
  const [ordinamenti, setOrdinamenti] = useState(
    ordinamentiToFiltri(initial.ordinamenti, initial.ordine)
  )

  const [loading, setLoading] = useState(false)

  const loader = useRef()
  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      handleFetching().then(fetchListing(1))
    }
  }, [filtri, ordinamenti])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (page > 1) handleFetching().then(fetchListing(page)) // per infinite loader
    }
  }, [page])

  useEffect(() => {
    window.addEventListener('scroll', handleLoader)

    const checkInfiniteScroll = setTimeout(() => handleLoader(), 1000)

    return () => {
      clearTimeout(checkInfiniteScroll)
      window.removeEventListener('scroll', handleLoader)
    }
  })

  const handleLoader = () => {
    setTimeout(() => {
      if (inViewport(loader.current) && !loading) {
        setPage(page + 1)
      }
    }, 500) // ipotetico
  }

  const handleFetching = async () => {
    if (!loading) setLoading(true)
  }

  const fetchListing = async (pageNumber) => {
    if (pageNumber > numPages) return

    const params = {
      p: pageNumber,
      o: ordinamenti.find((o) => o.selected)?.value,
      categoria_id: categoria,
      filtri_id: filtri
        .map((attr) => attr.valori_disponibili.filter((v) => v.selezionato).map((v) => v.value)) // array di array di selezionati
        .filter((attr) => attr.length > 0) // solo quelli con almento 1 valore selezionato
        .reduce((a, b) => a.concat(b), []) // unisco in array a 1 livello
        .join('/'),
    }

    api
      .get(LISTING, { params })
      .then((response) => {
        if (pageNumber === 1) {
          initializeListing(response.data.listing).then(setLoading(false))
        } else {
          infiniteLoading(response.data.listing).then(setLoading(false))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const initializeListing = async (listing) => {
    if (page > 1) setPage(1) // reset for infinite loading
    if (numPages !== listing.num_pages) setNumPages(listing.num_pages)
    if (prodottiInfinite.length) setProdottiInfinite([])
    setProdotti(listing.elementi)
    handleDisabilitati(listing.attributi)
  }

  const infiniteLoading = async (listing) => {
    setProdottiInfinite(prodottiInfinite.concat(listing.elementi))
  }

  const handleOrdinamento = (value) => {
    let newOrdinamenti = ordinamenti.slice()
    for (let i = 0; i < newOrdinamenti.length; i++)
      newOrdinamenti[i].selected = newOrdinamenti[i].value === value
    setOrdinamenti(newOrdinamenti)
  }

  const handleFilters = (action, name, value) => {
    let newFiltri = filtri.slice()
    let attrIndex = newFiltri.findIndex((f) => f.name === name)
    if (attrIndex > -1) {
      let valueIndex = newFiltri[attrIndex].valori_disponibili.findIndex((v) => v.value === value)
      if (valueIndex > -1) {
        switch (action) {
          case 'remove':
            newFiltri[attrIndex].valori_disponibili[valueIndex].selezionato = false
            break
          case 'toggle':
            newFiltri[attrIndex].valori_disponibili[valueIndex].selezionato =
              !newFiltri[attrIndex].valori_disponibili[valueIndex].selezionato
            break
        }
      }
    }
    setFiltri(newFiltri)
  }

  function handleDisabilitati(attributi) {
    const disabled = attributi
      .map((a) => a.valori_disponibili.filter((v) => v.disabilitato).map((v) => v.id))
      .flat()
    setDisabilitati(disabled)
  }

  useEffect(() => {
    if (prodotti.length) trackingProductImpression(prodotti, waList)
  }, [prodotti])

  useEffect(() => {
    if (prodotti.length) trackingProductImpression(prodottiInfinite, waList, prodotti.length)
  }, [prodottiInfinite])

  const waList = useMemo(
    () => LABEL_ORDINAMENTO[ordinamenti.find((o) => o.selected).value],
    [ordinamenti]
  )

  const filtriSmart = useMemo(
    () =>
      filtri.map((a) => ({
        ...a,
        valori_disponibili: a.valori_disponibili.map((v) => ({
          ...v,
          disabilitato: disabilitati.includes(v.value),
        })),
      })),
    [filtri, disabilitati]
  )

  return (
    <section className={`listing ` + classes}>
      {isClientSide && (
        <Filters
          attributi={filtriSmart}
          onChange={handleFilters}
          ordinamenti={ordinamenti}
          onChangeOrdinamento={handleOrdinamento}
        />
      )}
      <div className="listing__list row">
        {prodotti?.length ? (
          prodotti.map((p, index) => (
            <div className="col-6 col-md-4" key={'prodotto-' + p.id}>
              <ProdottoCard
                prodotto={p}
                waPosition={index + 1}
                waList={waList}
                preLoad={index < 4}
                lazyLoad={index > 3}
              />
            </div>
          ))
        ) : (
          <div className="listing__empty col">
            {t('Non sono presenti prodotti per questa categoria o per i parametri selezionati')}
          </div>
        )}
        {prodottiInfinite.map((p, index) => (
          <div className="col-6 col-md-4" key={'prodotto-' + p.id}>
            <ProdottoCard prodotto={p} waPosition={index + 1 + prodotti.length} waList={waList} />
          </div>
        ))}
      </div>
      {page < numPages && (
        <div className="listing__loader" ref={loader}>
          <InfiniteLoader />
        </div>
      )}
    </section>
  )
}

export default Listing
